import logo from "../assets/logo.png";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faGithub,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Add the icons to the library
library.add(faFacebook, faTwitter, faLinkedin, faGithub, faInstagram);


export default () => {
  return (
    <footer className="text-gray-500 bg-white px-4 py-5 max-w-screen-xl mx-auto md:px-8">
      <div className="max-w-lg sm:mx-auto sm:text-center">
        <p className="leading-relaxed mt-2 text-[15px]">
          Thank you for taking the time to read my profile. Feel free to contact
          me if you want to know more about me or if you have any opportunities
          that may suit my skills and interests.
        </p>
        <img src={logo} className="w-32 sm:mx-auto" />

      </div>

      
      <style jsx>{`
        .svg-icon path,
        .svg-icon polygon,
        .svg-icon rect {
          fill: currentColor;
        }
      `}</style>
    </footer>
  );
};
