import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faAward, faLightbulb, faHandsHelping, faMicroscope, faCode, faLaptop, faStar, faGavel, faMedal, faHeartbeat } from '@fortawesome/free-solid-svg-icons';
import Gallery from './Gallery';
export default function Achievements() {
    const achievements = [
        {
            title: "1st Runner-Up at Ideathon",
            prize: "₹50,000 Cash Prize and title winner of Out of the Box Thinker",
            description: "Secured the 1st Runner-Up position and received a ₹50,000 cash prize at Ideathon, hosted by Cloud Analogy in Haryana. Demonstrated exceptional problem-solving and innovative thinking.",
            icon: faTrophy,
            organizers: "LearNowX Cloud Analogy"
        },
        {
            title: "Top 10 Startups across India at MeitY Grand Challenge",
            prize: "Recognition as Top 10 Startup",
            description: "Recognized as one of the Top 10 Startups across India in the MeitY Grand Challenge held at IIT Hyderabad. Demonstrated innovative solutions and entrepreneurial potential, standing out among numerous participants.",
            icon: faStar,
            organizers: "IIT Hyderabad"
        },
        {
            title: "First Runner-Up and Grand Prize Winner at Pitch Fest 2023",
            prize: "₹15,000 Cash Prize",
            description: "Achieved the 1st Runner-Up position and was awarded a cash prize of ₹15,000 at Pitch Fest 2023 held at Sri Eshwar College of Engineering. Presented a compelling business idea and demonstrated effective communication and pitching skills, earning top honors.",
            icon: faAward,
            organizers: "Sri Eshwar College of Engineering"
        },
        {
            title: "First Runner-Up in GuviDanJRhackathon 2024",
            prize: "Winner of Goodies worth Rs 5,000, Worth Rs 2000 premium course, 2-Month Internship",
            description: "Achieved the First Runner-Up position at GuviDanJRhackathon 2024 held at IIT-M Research Park. Received exciting prizes including goodies worth Rs 5,000, access to a premium course worth Rs 2000, and a 2-month internship opportunity.",
            icon: faMedal,
            organizers: "GUVI Geek Networks"
        },        
        {
            title: "Agent of Change in Tech - Tech for Food Hackathon",
            prize: "Title winner Agent of Change in Tech",
            description: "Awarded with the title of 'Agent of Change in Tech' for outstanding contributions at the Tech for Food Hackathon organized by Google Developers Group Coimbatore. Recognized for innovative ideas and impactful initiatives in the tech and food industry.",
            icon: faLightbulb,
            organizers: "Google Developers Group Coimbatore"
            
        },
        {
            title: "TNSI EDII-TN Ideation Camp",
            prize: "Selected Participant",
            description: "Recognized for innovative ideas and selected as a participant for the TNSI EDII-TN Ideation Camp, fostering entrepreneurial thinking and problem-solving skills. Demonstrated creativity and entrepreneurial potential.",
            icon: faHandsHelping,
            organizers: "TNSI EDII-TN"
        },
        {
            title: "DevHack 2023",
            prize: "Finalist",
            description: "Advanced to the finals of DevHack 2023, a prestigious competition organized by Startup TN in partnership with Thiagarajar College of Engineering, Maxelerator Foundation, Young Indians Madurai, and Google Developers Group. Showcased exceptional technical skills and innovative thinking.",
            icon: faCode,
            organizers: "Startup TN, Thiagarajar College of Engineering, Maxelerator Foundation, Young Indians Madurai, and Google Developers Group"
        },
        {
            title: "Mini Project Expo 2023",
            prize: "Second Prize",
            description: "Secured the second prize in the Mini Project Expo held at Sri Eshwar College of Engineering. Demonstrated outstanding project implementation, technical proficiency, and effective presentation abilities, showcasing the ability to develop and present innovative ideas in a competitive environment.",
            icon: faLaptop,
            organizers: "Sri Eshwar College of Engineering",
        },
        {
            title: "The Pitch 2023",
            prize: "Semi-Finalist",
            description: "Achieved a semi-finalist position in The Pitch 2023, held as part of the 15th edition of Coimbatore Vizha, organized by Startup TN. Presented a compelling business idea, demonstrating effective communication and pitching skills to a panel of industry experts and investors.",
            icon: faStar,
            organizers: "Startup TN and Coimbatore Vizha",
        },
        // {
        //     title: "Gold Badge for Python",
        //     prize: "Received a Gold Badge",
        //     description: "Received a Gold Badge from HackerRank, recognizing exceptional proficiency in Python programming. Demonstrated advanced problem-solving skills and expertise in Python language features.",
        //     icon: faGavel,
        //     organizers: "HackerRank",
        // },
        {
            title: "SECE Internal Hackathon 2022",
            prize: "Hackathon Finale Participant",
            description: "Reached the finale of the Internal Hackathon conducted by Sri Eshwar College of Engineering. Showcased innovative problem-solving skills and collaborated effectively with a team in developing a cutting-edge solution within a limited timeframe.",
            icon: faMicroscope,
            organizers: "Sri Eshwar College of Engineering",
        },
        {
            title: "MEDICNIK at Karpagam Academy of Higher Education",
            prize: "Consolation Prize",
            description: "Awarded a consolation prize in MEDICNIK, a national-level technical symposium, for presenting a solution that demonstrated technical competence and effective presentation skills. The solution showcased innovative ideas and practical implementation in the Medical domain.",
            icon: faHeartbeat,
            organizers: "Karpagam Academy of Higher Education",
        },
    ];

  return (
    <section id="achievements" className="py-14">
            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                <div className="relative max-w-2xl mx-auto sm:text-center">
                    <div className="relative z-10">
                        <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                            Showcase of Achievements
                        </h3>
                        <p className="mt-3">
                            Explore some of my notable achievements in various competitions and events.
                        </p>
                    </div>
                    <div className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]" style={{ background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)" }}></div>
                </div>
                <div className="relative mt-12">
                <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
                    {achievements.map((item) => (
                        <li key={item.title} className="bg-white space-y-3 p-4 border rounded-lg">
                            <div className="text-customYellow pb-2">
                                <FontAwesomeIcon icon={item.icon} className="text-3xl" />
                            </div>
                            <h4 className="text-lg text-gray-800 font-semibold">
                                {item.title}
                            </h4>
                            <p className="text-yellow-600 pb-2">
                                {item.prize}
                            </p>
                            <p className="text-gray-500 pb-2">
                                Organized by: {item.organizers}
                            </p>
                            <p>
                                {item.description}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
            </div>
            <Gallery />
        </section>
  )
}
