import React from "react";
import heroImage from "../assets/hero.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faGithub,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import SocialMedia from "./SocialMedia";
import SlideShow from "./Slideshow";

const Hero = () => {
  const handleScrollToContact = () => {
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
    <section className="min-h-screen flex items-center relative">
      <div className="max-w-screen-xl mx-auto py-4 px-4 md:px-8 w-full relative z-10 gap-5 items-center lg:flex">
        <div className="flex-1 max-w-lg py-5 sm:mx-auto sm:text-center lg:max-w-max lg:text-left">
          <h3 className="text-3xl text-gray-800 font-semibold md:text-4xl">
            Welcome to My World of{" "}
            <span className="text-yellow-500">Innovation and Creativity!</span>
          </h3>
          <p className="text-gray-500 leading-relaxed mt-3">
            I'm Mahsook, a dynamic and forward-thinking professional with a
            passion for technology and innovation. a versatile Full Stack Developer proficient in the MERN stack, and a creative UI/UX designer.With expertise in MongoDB, Express.js, React.js, and Node.js, I seamlessly blend robust application development with captivating design.I bring a unique combination of technical prowess and design finesse. 
          
          </p>
          <SocialMedia />
          <a
            className="inline-block py-2 px-4 text-white font-medium bg-gray-800 duration-150 hover:bg-gray-700 active:bg-gray-900 rounded-lg shadow-md hover:shadow-none mt-3"
            onClick={handleScrollToContact}
            style={{ cursor: "pointer" }}
          >
            Get Connect
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 ml-1 duration-150 group-hover:translate-x-1 inline-block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 7l5 5m0 0l-5 5m5-5H6"
              />
            </svg>
          </a>
        </div>
        <div className="flex-1 mt-5 mx-auto sm:w-9/12 lg:mt-0 lg:w-auto">
          <img src={heroImage} alt="Mahsook" className="w-full" />
        </div>
      </div>
    </section>
    <SlideShow />
    </>
  );
};

export default Hero;
