import React, { useState, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll";
import logo from "../assets/logo.png";

const NavBar = () => {
  const [isNavFixed, setNavFixed] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setNavFixed(scrollPosition > 0);

      const sections = [
        "top",
        "experiences",
        "education",
        "skills",
        "projects",
        "certificates",
        "achievements",
        "contact",
      ];
      for (let i = sections.length - 1; i >= 0; i--) {
        const element = document.getElementById(sections[i]);
        if (element && scrollPosition >= element.offsetTop - 50) {
          setActiveSection(sections[i]);
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuItemClick = () => {
    setMobileMenuOpen(false);
  };

  const navigation = [
    { title: "Experiences", path: "experiences" },
    { title: "Education", path: "education" },
    { title: "Skills", path: "skills" },
    { title: "Projects", path: "projects" },
    { title: "Certificates", path: "certificates" },
    { title: "Achievements", path: "achievements" },
    { title: "Contact", path: "contact" },
  ];

  return (
    <nav
      className={`bg-white w-full border-b ${
        isNavFixed ? "fixed top-0 z-50" : ""
      }`}
    >
      <div className="items-center px-4 max-w-screen-xl mx-auto md:flex md:px-8">
        <div className="flex items-center justify-between py-3 md:py-5 md:block">
          <ScrollLink to="top" smooth={true} duration={500}>
            <img src={logo} width={120} height={50} alt="Float UI logo" />
          </ScrollLink>
          <div className="md:hidden">
            <button
              className="text-gray-700 outline-none p-2 rounded-md focus:border-gray-400 focus:border"
              onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
            >
              {isMobileMenuOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 8h16M4 16h16"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
        <div
          className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
            isMobileMenuOpen ? "block" : "hidden"
          }`}
        >
          <ul className="justify-center items-center space-y-8 md:flex md:space-x-6 md:space-y-0 cursor-pointer">
            {navigation.map((item, idx) => (
              <li
                key={idx}
                className={`text-gray-600 p-2 rounded-lg hover:text-yellow-500 ${
                  activeSection === item.path ? "bg-gray-200" : ""
                }`}
              >
                <ScrollLink
                  to={item.path}
                  smooth={true}
                  duration={500}
                  onClick={handleMenuItemClick}
                >
                  {item.title}
                </ScrollLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="hidden md:inline-block">
          <ScrollLink to="contact" smooth={true} duration={500}>
            <button className="py-3 px-4 text-white bg-gray-800 hover:bg-gray-600 rounded-md shadow">
              Get Connect
            </button>
          </ScrollLink>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
