import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils, faCode, faPalette } from '@fortawesome/free-solid-svg-icons';

export default () => {

    const experiences = [
        {
            icon: <FontAwesomeIcon icon={faUtensils} className="w-6 h-6" />,
            title: "NearTreat",
            position: "Founder and CEO",
            date: "11/2022 - Present",
            description: [
                "Developed a full-stack web application for NearTreat, platform that offers live location tracking and real-time availability of street food recipes, using the MERN stack.It is currently in MVP stage.",
            ]
        },
        {
            icon: <FontAwesomeIcon icon={faCode} className="w-6 h-6" />,
            title: "JustPrompt",
            position: "Founder and CEO",
            date: "6/2023 - 7/2023",
            description: [
                "Architected and developed a Low Code/No Code platform for JustPrompt, a startup that offers a platform for creating web applications without writing code, using React, Node.js, and MongoDB. The platform is currently in beta testing.",
            ]
        },
        {
            icon: <FontAwesomeIcon icon={faPalette} className="w-6 h-6" />,
            position: "Design Intern",
            title: "GUVI Geek Networks, IITM Research Park",
            date: "Mar 2024 - May 2024",
            description: [
                "Secured this internship opportunity at Guvi by winning the Guvi Design Hackathon. During the internship, gained comprehensive experience in UX/UI design, covering product research, heuristic analysis, user research, and interviews."
            ]
        },
        {
            icon: <FontAwesomeIcon icon={faCode} className="w-6 h-6" />,
            title: "The Better Tomorrow",
            position: "MERN Stack Intern",
            date: "6/2023 - 7/2023",
            description: [
                "During my internship at The Better Tomorrow, a prominent web development company, I gained hands-on experience working with the MERN stack."
            ]
        },
    ];

    // {
    //     icon: <FontAwesomeIcon icon={faPalette} className="w-6 h-6" />,
    //     title: "UI/UX Designer",
    //     position: "Dan Jr. Wedding Biryani",
    //     date: "1/2024 - 1/2024",
    //     description: [
    //         "Designed and implemented a responsive web UI/UX for Dan Jr. Wedding Biryani's biryani ordering platform using Figma and Canva, collaborating with developers to ensure seamless integration and adherence to client specifications for optimal usability.",
            
    //     ]
    // },
    return (
        <section id="experiences" className="relative py-28 bg-gray-900">
            <div className="relative z-10 max-w-screen-xl mx-auto px-4 text-gray-300 justify-between gap-24 lg:flex md:px-8">
                <div className="max-w-xl">
                    <h3 className="text-white text-3xl font-semibold sm:text-4xl">
                     Professional Experience
                    </h3>
                  
                </div>
                <div className="mt-12 lg:mt-0">
                    <ul className="grid gap-8 sm:grid-cols-2">
                    {
    experiences.map((experience, idx) => (
        <li key={idx} className="flex gap-x-4">
            <div className="flex-none w-12 h-12 bg-customYellow text-white rounded-lg flex items-center justify-center">
                {experience.icon}
            </div>
            <div>
                <h4 className="text-lg text-gray-100 font-semibold">
                    {experience.title}
                </h4>
                <p className="mt-3">
                    {experience.position} | {experience.date}
                </p>
                <ul className="mt-3 text-gray-300">
                    {experience.description.map((point, index) => (
                        <li key={index}>{point}</li>
                    ))}
                </ul>
            </div>
        </li>
    ))
}

                    </ul>
                </div>
            </div>
            <div className="absolute inset-0 max-w-md mx-auto h-72 blur-[118px]" style={{ background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)" }}></div>
        </section>
    )
}