import React from 'react';
import CloudAnalogyLogo from '../assets/Recognitions/cloudanalogy.png';
import GDGLogo from '../assets/Recognitions/gdg.png';
import GuviLogo from '../assets/Recognitions/guvi.png';
import IITHLogo from '../assets/Recognitions/iith.png';
import KarpagamLogo from '../assets/Recognitions/karpagam.png';
import LearnowxLogo from '../assets/Recognitions/Learnowx.png';
import MeitYStartupHubLogo from '../assets/Recognitions/MeitY Startup Hub.png';
import SECELogo from '../assets/Recognitions/sece.png';
import SINELogo from '../assets/Recognitions/sine.jpeg';
import StartupTNLogo from '../assets/Recognitions/startuptn.png';
import TCELogo from '../assets/Recognitions/tce.png';
import TNSILogo from '../assets/Recognitions/tnsi.png';
import VizhaLogo from '../assets/Recognitions/vizha.png';
import ZohoLogo from '../assets/Recognitions/zoho.png';
import MeityLogo from '../assets/Recognitions/meity.png';

const logos = [
  { src: MeitYStartupHubLogo, alt: 'MeitY Startup Hub' },
  { src: SINELogo, alt: 'SINE' },
  { src: TNSILogo, alt: 'TNSI' },
  { src: IITHLogo, alt: 'IIT Hyderabad' },
  { src: MeityLogo, alt: 'Meity' },
  { src: ZohoLogo, alt: 'Zoho' },
  { src: CloudAnalogyLogo, alt: 'Cloud Analogy' },
  { src: GDGLogo, alt: 'GDG' },
  { src: GuviLogo, alt: 'Guvi' },
  { src: LearnowxLogo, alt: 'Learnowx' },
  { src: TCELogo, alt: 'TCE' },
  { src: SECELogo, alt: 'SECE' },
  { src: StartupTNLogo, alt: 'StartupTN' },
  { src: VizhaLogo, alt: 'Vizha' },
  { src: KarpagamLogo, alt: 'Karpagam' },
];

export default function Recognitions() {
  return (
    <div className="py-28">
    <div className="max-w-screen-xl mx-auto px-4 flex-wrap gap-x-12 justify-between items-center md:flex md:px-8 lg:flex-nowrap">
        <div className="max-w-xl space-y-3">
            <h3 className="text-customYellow text-sm font-semibold">
                Recognitions
            </h3>
            <p className="text-black text-3xl font-semibold sm:text-4xl">
                My projects have been recognized by various organizations and institutions.
            </p>
            <p className="text-gray-800">
                Explore some of the organizations that have recognized my work and projects.
                
            </p>
        </div>
        <div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 justify-items-center">
        {logos.map((logo, index) => (
          <div
            key={index}
            className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out"
          >
            <img
              src={logo.src}
              alt={logo.alt}
              className="h-20 mx-auto object-contain"
            />
          </div>
        ))}
      </div>
    </div>
    </div>
</div>
  );
}
