import TechSkills from "./TechSkills";

export default () => {
  // Array of core competencies
  const coreCompetencies = [
    "Software Development",
    "Web Development",
    "Design",
    "Problem Solving",
    "IoT Expertise",
    "Data Structures and Algorithms",
    "Team Collaboration",
    "Project Management",
    "Cloud Computing",
    "Technical Communication",
    "Innovation",
    "Certifications",
  ];

  return (
    <>
    <div id="skills" className="py-14 bg-gray-900">
      <div className="max-w-screen-xl mx-auto px-4 md:px-8">
        <div className="max-w-xl mx-auto text-center">
          <h3 className="text-white text-3xl font-semibold sm:text-4xl">
            My Core Competencies
          </h3>
        </div>
        <div className="mt-12 flex justify-center">
          <ul className="text-gray-300 inline-grid grid-cols-2 gap-x-10 gap-y-6 md:gap-x-16 md:grid-cols-3 lg:grid-cols-4">
            {coreCompetencies.map((competency, index) => (
              <li key={index}>
                {competency}
              </li>
            ))}
          </ul>
        </div>
        <div>
        <h3 className="text-white text-3xl font-semibold sm:text-4xl text-center mt-7 mb-7">
        Technical Proficiency
          </h3>
    <TechSkills/>
  </div>
      </div>
      
    </div>
    
  </>
  );
};
