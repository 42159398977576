import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faLinkedin, faGithub, faInstagram } from "@fortawesome/free-brands-svg-icons";
import SocialMedia from "./SocialMedia";
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Contact() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const serviceID = 'service_bdvfbvo';
    const templateID = 'template_i7yj08g';
    const userID = 'iD6eSbwQ2NjIhMYRN';
    const form = e.target;

    try {
      const response = await emailjs.sendForm(serviceID, templateID, form, userID);
      console.log('Email sent successfully:', response);

      setIsSuccess(true);
      form.reset(); 
      toast.success('Message sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      setIsError(true);
      toast.error('Oops! Something went wrong. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main id="contact" className="py-14 bg-gray-900">
      <div className="max-w-screen-xl mx-auto px-4 text-gray-300 md:px-8">
  <div className="max-w-lg mx-auto gap-12 justify-between lg:flex lg:max-w-none">
    <div className="max-w-lg space-y-3">
      <h3 className="text-customYellow font-semibold">Contact</h3>
      <p className="text-white text-3xl font-semibold sm:text-4xl">
        Get in touch
      </p>
      <p>
        Connect with me on social media or send me a message using the
        form below.
      </p>
      <div>
        <SocialMedia />
      </div>
    </div>
    <div className="flex-1 mt-12 sm:max-w-lg lg:max-w-md">
            {isSuccess && (
              <div className="text-green-500 font-medium mb-4">
                Message sent successfully!
              </div>
            )}
            {isError && (
              <div className="text-red-500 font-medium mb-4">
                Oops! Something went wrong. Please try again.
              </div>
            )}
            <form onSubmit={handleSubmit} className="space-y-5">
              <div>
                <label htmlFor="fullName" className="font-medium">
                  Full name
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  required
                  className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-customYellow shadow-sm rounded-lg"
                />
              </div>
              <div>
                <label htmlFor="email" className="font-medium">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-customYellow shadow-sm rounded-lg"
                />
              </div>
              <div>
                <label htmlFor="subject" className="font-medium">
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  required
                  className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-customYellow shadow-sm rounded-lg"
                />
              </div>
              <div>
                <label htmlFor="message" className="font-medium">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  required
                  className="w-full mt-2 h-36 px-3 py-2 resize-none appearance-none bg-transparent outline-none border focus:border-customYellow shadow-sm rounded-lg"
                ></textarea>
              </div>
              <button
                disabled={isLoading}
                className="w-full px-4 py-2 text-white font-medium bg-customYellow hover:bg-yellow-500 active:bg-customYellow rounded-lg duration-150"
              >
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
  </div>
</div>
<ToastContainer />
    </main>
  );
}
