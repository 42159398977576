import React from "react";

const FullStack = [
  {
    name: "MongoDB",
    logoUrl: "https://www.vectorlogo.zone/logos/mongodb/mongodb-ar21.svg",
  },
  {
    name: "Express.js",
    logoUrl: "https://www.vectorlogo.zone/logos/expressjs/expressjs-ar21.svg",
  },
  {
    name: "React.js",
    logoUrl: "https://www.vectorlogo.zone/logos/reactjs/reactjs-ar21.svg",
  },
  {
    name: "Node.js",
    logoUrl: "https://www.vectorlogo.zone/logos/nodejs/nodejs-ar21.svg",
  },
];
const backendDevelopmentSkills = [
  {
    name: "C/C++",
    logoUrl: "https://www.freeiconspng.com/uploads/c--logo-icon-0.png",
    width: 80,
    height: 80,
    },
  {
    name: "Python",
    logoUrl: "https://www.vectorlogo.zone/logos/python/python-icon.svg",
  },
  {
    name: "JavaScript",
    logoUrl: "https://logos-world.net/wp-content/uploads/2023/02/JavaScript-Logo-500x281.png",
    width: 120,
  },
 
  {
    name: "Java",
    logoUrl: "https://www.vectorlogo.zone/logos/java/java-icon.svg",
  },
];
const databaseandcloud = [
  {
    name: "MongoDB",
    logoUrl: "https://www.vectorlogo.zone/logos/mongodb/mongodb-ar21.svg",
  },
  {
    name: "Firebase",
    logoUrl: "https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg",
  },
  {
    name: "SQL",
    logoUrl: "https://www.pipelinersales.com/wp-content/uploads/2019/03/azure.png",
  }
];



const toolsSkills = [
  {
    name: "VS Code",
    logoUrl:
      "https://www.vectorlogo.zone/logos/visualstudio_code/visualstudio_code-ar21.svg",
  },
  {
    name: "Arduino IDE",
    logoUrl: "https://www.vectorlogo.zone/logos/arduino/arduino-ar21.svg",
  },
  {
    name: "GitHub",
    logoUrl: "https://www.vectorlogo.zone/logos/github/github-ar21.svg",
  },
  {
    name: "Git",
    logoUrl: "https://www.vectorlogo.zone/logos/git-scm/git-scm-ar21.svg",
  },
  {
    name: "Netlify",
    logoUrl: "https://www.vectorlogo.zone/logos/netlify/netlify-ar21.svg",
  },
  {
    name: "Vercel",
    logoUrl: "https://www.vectorlogo.zone/logos/vercel/vercel-ar21.svg",
  },
];

const Design = [
  {
    name: "Canva",
    logoUrl: "https://freelogopng.com/images/all_img/1656733637logo-canva-png.png",
    width: 80,
  },
  { 
    name: "Figma",
    logoUrl: "https://www.vectorlogo.zone/logos/figma/figma-icon.svg",    
    width: 80,    
  },
];  

const SkillCategory = ({ skills, title }) => (
<div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/3 p-4">
  <div style={{ display: 'inline-block', width: '90%', height: '90%' }}>
    <div className="bg-white p-4 border rounded-lg h-full">
      <div className="text-black font-medium text-center">{title}</div>
      <div className="flex flex-wrap -mx-2 h-full">
        {skills.map((skill) => (
          <div key={skill.name} className="w-1/2 p-2">
            <img
              src={skill.logoUrl}
              alt={skill.name}
              style={{ width: skill.width, height: skill.height }}
              className="object-contain mx-auto mb-2"
            />
          </div>
        ))}
      </div>
    </div>
  </div>
</div>
);

const TechSkills = () => (
  <div className="flex flex-wrap">
    <SkillCategory skills={FullStack} title="Full Stack Web Development" />
    <SkillCategory skills={backendDevelopmentSkills} title="Programming language" />
    <SkillCategory skills={databaseandcloud} title="Databases and Cloud Services" />
    <SkillCategory skills={Design} title="Design and Prototyping" />
    <SkillCategory skills={toolsSkills} title="Tools" />

  </div>
);

export default TechSkills;
