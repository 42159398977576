import React from 'react'
import itsme1 from "../assets/gallery/1.jpeg";
import itsme2 from "../assets/gallery/2.jpeg";
import itsme3 from "../assets/gallery/3.jpeg";
import itsme4 from "../assets/gallery/4.jpeg";
import itsme5 from "../assets/gallery/5.jpeg";
import itsme6 from "../assets/gallery/6.jpeg";
import itsme7 from "../assets/gallery/7.jpeg";
import itsme8 from "../assets/gallery/8.jpeg";
import itsme9 from "../assets/gallery/9.jpeg";
import itsme10 from "../assets/gallery/10.jpeg";
import itsme11 from "../assets/gallery/11.jpeg";
import itsme12 from "../assets/gallery/12.png";
import itsme13 from "../assets/gallery/13.jpg";
import itsme14 from "../assets/gallery/14.JPG";

export default function Gallery() {
  return (
    <section className="py-14">
    <div className="max-w-screen-xl mx-auto px-4 md:text-center md:px-8">
      <div className="max-w-xl md:mx-auto">
        <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
        Gallery of Accomplishments
        </h3>
        <p className="mt-3 text-gray-600">
        A Showcase of Noteworthy Accomplishments and Unforgettable Events in My Journey
        </p>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        <div className="grid gap-4">
          <div>
            <img
              className="h-auto max-w-full rounded-lg"
              src={itsme1}
              alt=""
            />
          </div>
          <div>
            <img
              className="h-auto max-w-full rounded-lg"
              src={itsme9}
              alt=""
            />
          </div>
          <div>
            <img
              className="h-auto max-w-full rounded-lg"
              src={itsme3}
              alt=""
            />
          </div>
        </div>
        <div className="grid gap-4">
          <div>
            <img
              className="h-auto max-w-full rounded-lg"
              src={itsme4}
              alt=""
            />
          </div>
          <div>
            <img
              className="h-auto max-w-full rounded-lg"
              src={itsme5}
              alt=""
            />
          </div>
          <div>
            <img
              className="h-auto max-w-full rounded-lg"
              src={itsme6}
              alt=""
            />
          </div>
          <div>
            <img
              className="h-auto max-w-full rounded-lg"
              src={itsme13}
              alt=""
            />
          </div>          
        </div>
        <div className="grid gap-4">
          <div>
            <img
              className="h-auto max-w-full rounded-lg"
              src={itsme11}
              alt=""
            />
          </div>
          <div>
            <img
              className="h-auto max-w-full rounded-lg"
              src={itsme8}
              alt=""
            />
          </div>
          <div>
            <img
              className="h-auto max-w-full rounded-lg"
              src={itsme2}
              alt=""
            />
          </div>
          <div>
            <img
              className="h-auto max-w-full rounded-lg"
              src={itsme14}
              alt=""
            />
          </div>
        </div>
        <div className="grid gap-4">
          <div>
            <img
              className="h-auto max-w-full rounded-lg"
              src={itsme10}
              alt=""
            />
          </div>
          <div>
            <img
              className="h-auto max-w-full rounded-lg"
              src={itsme7}
              alt=""
            />
          </div>
          <div>
            <img
              className="h-auto max-w-full rounded-lg"
              src={itsme12}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}
