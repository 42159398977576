import React from "react";

const Certificates = () => {
  const certificates = [
    {
      domain: "UI/UX Design",
      name: "Figma",
      issuingOrganization : "Guvi Geek Networks, IITM Research Park",
      issueDate: "Jan 2024",
      credentialURL: "https://www.guvi.in/verify-certificate?id=415iO7R1CDc3075671&course=figmatamil",
    },
    {
      domain: "Cloud Computing",
      name: "Cloud computing",
      issuingOrganization: "NPTEL",
      issueDate: "Nov 2023",
      credentialURL:
        "https://internalapp.nptel.ac.in/NOC/NOC23/SEM2/Ecertificates/106/noc23-cs89/Course/NPTEL23CS89S3338046620257901.pdf",
    },
    {
      domain: "AI",
      name: "AI For India 2.0",
      issuingOrganization: "GUVI Geek Networks, IITM Research Park",
      issueDate: "Aug 2023",
      credentialURL:
        "https://www.guvi.in/verify-certificate?id=b66Q1W542A0mP31239&course=ai_for_in_ta",
    },
    {
      domain: "Chatbot Development",
      name: "Developing Chatbots with Azure",
      issuingOrganization: "LinkedIn",
      issueDate: "Feb 2023",
      credentialURL:
        "https://www.linkedin.com/learning/certificates/a8b033747d0788b2f8e2f375821ab3d6c973211a9c0522d8fdd40eb9f02f06b2",
    },
    {
      domain: "Programming and Development",
      name: "The joy of computing using Python",
      issuingOrganization: "NPTEL",
      issueDate: "Nov 2023",
      credentialURL:
        "https://archive.nptel.ac.in/content/noc/NOC22/SEM2/Ecertificates/106/noc22-cs122/Course/NPTEL22CS122S2448184810038148.jpg",
    },
    {
      domain: "Programming and Development",
      name: "Mastering Data Structures & Algorithms using C and C++",
      issuingOrganization: "Udemy",
      issueDate: "Oct 2022",
      credentialURL:
        "https://www.udemy.com/certificate/UC-e9250ef2-2d4e-4db4-b2dd-4df68434ee29/",
    },
    {
      domain: "Programming and Development",
      name: "Crash Course on Python",
      issuingOrganization: "Coursera",
      issueDate: "Aug 2021",
      credentialURL:
        "https://www.coursera.org/account/accomplishments/certificate/53HSXAE8WM57",
    },
    {
      domain: "Programming and Development",
      name: "Python",
      issuingOrganization: "HackerRank",
      issueDate: "Aug 2021",
      credentialURL: "https://www.hackerrank.com/certificates/ddfcf90d1a78",
    },
    {
      domain: "Programming and Development",
      name: "Python",
      issuingOrganization: "GUVI Geek Networks, IITM Research Park",
      issueDate: "July 2021",
      credentialURL:
        "https://www.guvi.in/certificate.html?id=rd0853627FB1V37M2O",
    },
    {
      domain: "Data Science and Machine Learning",
      name: "Diploma in Python with Data Science and Machine Learning",
      issuingOrganization: "Udemy",
      issueDate: "June 2021",
      credentialURL:
        "https://www.udemy.com/certificate/UC-25e4567d-8173-4874-ad91-2a4ec5d2e7e4/",
    },
    {
      domain: "Data Science",
      name: "Data Science Foundations - Level 1",
      issuingOrganization: "IBM",
      issueDate: "Jan 2022",
      credentialURL:
        "https://www.credly.com/badges/1c67945f-8c4e-4983-926d-52065279af64?source=linked_in_profile",
    },
    {
      domain: "Internet of Things (IoT)",
      name: "Introduction to IoT",
      issuingOrganization: "Cisco",
      issueDate: "Aug 2022",
      credentialURL:
        "https://www.credly.com/badges/994a1868-efb6-4d82-a2ae-e8a63a3ca02c/linked_in_profile",
    },
    {
      domain: "Database Management",
      name: "SQL - Standard",
      issuingOrganization: "SkillRack",
      issueDate: "Jan 2022",
      credentialURL: "http://www.skillrack.com/cert/265128/XQJ",
    },
  ];

  return (
    <div id="certificates" className="bg-gradient-to-b from-gray-900 via-gray-900 to-gray-800 p-10">
      <div className="max-w-screen-xl mx-auto">
        <div className="relative max-w-2xl mx-auto sm:text-center">
          <div className="relative z-10">
            <h3 className="text-white text-3xl font-semibold sm:text-4xl">
              Certifications
            </h3>
            <p className="mt-3 text-gray-300">
              List of certifications and their details
            </p>
          </div>
        </div>
        <div className="mt-12 shadow-sm border rounded-lg overflow-x-auto">
          <table className="w-full table-auto text-sm text-left">
            <thead className="bg-gray-50 text-gray-600 font-medium border-b">
              <tr>
                <th className="py-3 px-6">Domain</th>
                <th className="py-3 px-6">Certification</th>
                <th className="py-3 px-6">Issuing Organization</th>
                <th className="py-3 px-6">Issue Date</th>
                <th className="py-3 px-6">Credential URL</th>
              </tr>
            </thead>
            <tbody className="text-gray-300 divide-y">
              {certificates.map((certificate, idx) => (
                <tr key={idx}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {certificate.domain}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {certificate.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {certificate.issuingOrganization}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {certificate.issueDate}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-customYellow hover:text-yellow-600">
                    <a
                      href={certificate.credentialURL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Credential Link
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Certificates;
