import React from "react";
import Recognitions from "./Recognitions";

export default () => {
  const projects = [
    {
      name: "NearTreat",
      desc: "Bridging the gap between street food vendors and food lovers.ultimate destination for the hidden treasures of street food",
      domain: "Web development using MERN Stack",
      technology:
        "React JS, Node JS,Express JS, MongoDB,Maps API, Tailwind CSS, Firebase and more",
      website: "https://www.neartreat.in",
    },
    {
        name: "JustPrompt.ai",
        desc: "Developed a no-code platform to help small and medium scale businesses establish a strong online presence.",
        domain: "Web development using MERN Stack",
        technology: "React JS, Node JS, Express JS, MongoDB, Gemini API",
        website: "https://justprompt.mahsook.tech/",
      },
    {
      name: "TimelyTask",
      desc: "The TimelyTask project is a Web that aims to simplify task management and payment for blue-collar workers.",
      domain: "Web development using MERN Stack",
      technology: "React js, Node js, Express js, MongoDB and more",
      website: "https://timelytask.mahsook.tech",
    },
    {
      name: "QRMedisync",
      desc: "Designed and implemented a QR code-based drug dispensing system using a combination of embedded systems and web technologies.",
      domain: "Emebedded Systems and IoT",
      technology: "MERN Stack, NodeMCU, Servo Motor, QR Code Scanner and more",
    },
    {
      name: "Dan Jr. Wedding Biryani's",
      desc: "Designed and implemented a responsive web UI/UX for a biryani ordering platform. That is a fictional biryani ordering platform.",
      domain: "UI/UX Design",
      technology: "Figma, Canva and more",

      website:
        "https://www.figma.com/file/O2i8m8dQwE0wGwVFTHIyIE/Guvi?type=design&mode=design&t=1uoaxXFFlklvsimj-1",
    },
    {
      name: "GreenBite",
      desc: "Designed UI for a fictional organic food delivery service.That follows the principles of minimalism and simplicity.",
      domain: "UI/UX Design",
      technology: "Figma, Canva and more",
      website:
        "https://www.figma.com/file/dIma8LQf84proswVjLVmGw/GreenBite?type=design&mode=design&t=m1S3OnyD6Sx8uIgR-1",
    },
    {
      name: "Expense Tracker",
      desc: "Developed a comprehensive Expense Tracker application to help users manage their financial transactions efficiently.",
      domain: "Web development",
      technology: "React JS, Node JS, Express JS, Tailwind CSS, Canvas JS",
      website: "https://expensetracker.mahsook.tech/",
    },
    {
      name: "StuTrack",
      desc: "Tool that tracks the number of coding problems solved by each student in my department.",
      domain: "Web development",
      technology: "React JS, Tailwind CSS",
      website: "https://stutrack.mahsook.tech/",
    },
    {
      name: "Smart Ambulance Control System",
      desc: "Designed and implemented a sophisticated traffic control system for optimizing ambulance routes in healthcare.",
      domain: "Emebedded Systems and IoT",
      technology: "Arduino Uno,GPS Module,ECG Module and ESP 32 ",
    },
  ];

  return (
    <>
      <section id="projects" className="py-14">
        <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
          <div className="relative max-w-xl mx-auto sm:text-center">
            <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
              Explore some of my notable Projects
            </h3>
            <div className="mt-3 max-w-xl">
              <p>Innovations in Technology and Leadership</p>
            </div>
          </div>
          <div className="mt-16 justify-center gap-6 sm:grid sm:grid-cols-2 sm:space-y-0 lg:grid-cols-3">
            {projects.map((item, idx) => (
              <div
                key={idx}
                className={`relative flex-1 flex items-stretch flex-col rounded-xl border-2 mt-6 sm:mt-0 ${
                  item.isMostPop ? "mt-10" : ""
                }`}
              >
                {item.isMostPop ? (
                  <span className="w-32 absolute -top-5 left-0 right-0 mx-auto px-3 py-2 rounded-full border shadow-md bg-white text-center text-gray-700 text-sm font-semibold">
                    Most popular
                  </span>
                ) : (
                  ""
                )}
                <div className="p-8 space-y-4 border-b">
                  <span className="text-yellow-600 font-medium">
                    {item.name}
                  </span>
                  <p>{item.desc}</p>
                  <button
                    className="px-3 py-3 rounded-lg w-full font-semibold text-sm duration-150 text-white bg-customYellow hover:bg-yellow-500 active:bg-Yellow-700"
                    onClick={() =>
                      window.open(
                        item.website || item.videoURL || "#",
                        "_blank"
                      )
                    }
                  >
                    Project Link
                  </button>
                </div>
                <div className="p-8 space-y-3">
                  <p className=" text-gray-800 font-medium">Domain</p>
                  <p>{item.domain}</p>
                  <p className=" text-gray-800 font-medium">Tech Stack</p>
                  <p>{item.technology}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
