import NavBar from '../components/SpcNavBar';
import Footer from '../components/Footer';
const GuviInternReport = () => {
    const reportData = [
        {
            title: "Product Research for guvi.in",
            objective: [
              "Identify emerging industry trends relevant to guvi.in's target audience.",
              "Evaluate competitor products and strategies to identify gaps and opportunities for guvi.in."
            ],
            outcome: [
              "Identified a growing demand for interactive online learning platforms among guvi.in's target demographic.",
              "Conducted a competitive analysis, highlighting areas where guvi.in could differentiate itself through unique features and user experiences."
            ],
            difficulties: [
              "Accessing comprehensive data on emerging trends within the online learning industry.",
              "Analyzing competitor offerings while ensuring objectivity and avoiding bias."
            ],
            link: "https://www.figma.com/file/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?type=design&node-id=26%3A18662&mode=design&t=M1PJKbonLXDxHekY-1"
          },
          {
            title: "Heuristic Analysis in UX for guvi.in",
            objective: [
              "Evaluate guvi.in's existing user interface against established usability principles.",
              "Identify areas for improvement to enhance the overall user experience."
            ],
            outcome: [
              "Conducted a heuristic evaluation of guvi.in's platform, identifying issues such as inconsistent navigation and lack of clear feedback.",
              "Provided actionable recommendations for improving usability, including simplifying navigation and enhancing error messaging."
            ],
            difficulties: [
              "Balancing subjective evaluations with objective usability criteria.",
              "Prioritizing recommendations based on their potential impact on user satisfaction and engagement."
            ],
            link: "https://www.figma.com/file/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?type=design&node-id=0%3A1&mode=design&t=M1PJKbonLXDxHekY-1"
          },
          {
            title: "User Research for guvi.in",
            objective: [
              "Utilize qualitative and quantitative research methods to gain insights into user behavior, needs, and pain points on guvi.in.",
              "Identify opportunities for improving the user experience and driving product development on guvi.in."
            ],
            outcome: [
              "Generated valuable user insights through surveys, interviews, and usability testing, informing design decisions and driving improvements to guvi.in's user experience.",
              "Identified key pain points and user preferences, guiding the development of features and enhancements on guvi.in."
            ],
            difficulties: [
              "Recruiting diverse user participants representative of guvi.in's target audience.",
              "Synthesizing and analyzing large volumes of qualitative and quantitative data to extract actionable insights for guvi.in."
            ],
            link: "https://www.figma.com/file/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?type=design&node-id=26%3A18663&mode=design&t=M1PJKbonLXDxHekY-1"
          },
          {
            title: "User Interview for guvi.in",
            objective: [
              "Conduct user interviews to gather feedback, understand user preferences, and validate design decisions for guvi.in.",
              "Identify pain points, preferences, and unmet needs of guvi.in's target audience through qualitative research methods."
            ],
            outcome: [
              "Gathered qualitative feedback from users to validate design assumptions, identify pain points, and prioritize features for development on guvi.in.",
              "Identified user preferences and pain points, informing the development of user-centric solutions and enhancements on guvi.in."
            ],
            difficulties: [
              "Scheduling and coordinating interviews with users, ensuring a diverse participant pool representative of guvi.in's target audience.",
              "Effectively synthesizing and analyzing interview findings to extract actionable insights and recommendations for guvi.in."
            ],
            link: "https://www.figma.com/file/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?type=design&node-id=46%3A10546&mode=design&t=M1PJKbonLXDxHekY-1"
          },
          {
            title: "Analytic Platforms",
            objective: [
              "Utilize analytic platforms such as Google Analytics to track user interactions, identify patterns, and optimize user journeys.",
              "Leverage data-driven insights to inform design decisions and drive improvements to user experience across guvi.in's products and services."
            ],
            outcome: [
              "Analyzed user behavior and interactions on guvi.in using Google Analytics, identifying key trends and areas for optimization.",
              "Utilized data-driven insights to optimize user journeys, improve website navigation, and enhance overall user experience on guvi.in."
            ],
            difficulties: [
              "Interpreting and analyzing complex data sets to extract actionable insights relevant to guvi.in's business objectives.",
              "Aligning metrics and analytics data with guvi.in's strategic goals and user experience objectives."
            ],
            link: "https://www.figma.com/file/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?type=design&node-id=65%3A25442&mode=design&t=M1PJKbonLXDxHekY-1"
          },
          {
            title: "Product Manager vs. Product Designer",
            objective: [
              "Explore the relationship between product management and design processes to facilitate effective collaboration and product development on guvi.in.",
              "Identify opportunities for alignment and synergy between product management and design functions on guvi.in."
            ],
            outcome: [
              "Collaborated closely with product managers to align design efforts with product goals and user needs, ensuring a cohesive and user-centered approach to product development on guvi.in.",
              "Identified areas for improved communication and collaboration between product management and design teams, leading to more streamlined and effective product development processes on guvi.in."
            ],
            difficulties: [
              "Balancing the priorities and perspectives of product managers and designers to ensure alignment with guvi.in's strategic objectives.",
              "Effectively communicating design decisions and rationale to product managers, stakeholders, and other team members."
            ],
            link: "https://www.figma.com/file/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?type=design&node-id=85%3A35612&mode=design&t=M1PJKbonLXDxHekY-1"
          },
          {
            title: "Development Methodology",
            objective: [
              "Learn and apply agile methodologies to design processes, ensuring flexibility and responsiveness to changing requirements on guvi.in.",
              "Incorporate agile principles into design workflows to enable iterative development, continuous feedback, and rapid iteration cycles on guvi.in's projects."
            ],
            outcome: [
              "Adopted agile methodologies in design processes, enabling more flexible and responsive approaches to product development on guvi.in.",
              "Implemented iterative design cycles and regular feedback loops, resulting in faster delivery of high-quality design solutions on guvi.in."
            ],
            difficulties: [
              "Adapting to agile practices and methodologies, particularly in the context of design processes and workflows on guvi.in.",
              "Managing scope creep and maintaining alignment between design and development teams while working within an agile framework on guvi.in."
            ],
            link: "https://www.figma.com/file/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?type=design&node-id=92%3A5102&mode=design&t=M1PJKbonLXDxHekY-1"
          },
          {
            title: "Design Systems",
            objective: [
              "Contribute to the development and maintenance of design systems to ensure consistency and scalability across products on guvi.in.",
              "Establish and evolve design systems to streamline workflows, maintain design consistency, and improve collaboration across teams on guvi.in."
            ],
            outcome: [
              "Contributed to the development and documentation of design system components and guidelines, ensuring consistency and scalability across guvi.in's products and services.",
              "Helped establish and evolve design systems to streamline design workflows, improve collaboration, and maintain consistency across guvi.in's design projects."
            ],
            difficulties: [
              "Balancing flexibility with consistency in design systems to accommodate the diverse needs of guvi.in's products and services.",
              "Ensuring adoption and adherence to design system components and guidelines across design teams and projects on guvi.in."
            ],
            link: "https://www.figma.com/file/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?type=design&node-id=115%3A3407&mode=design&t=M1PJKbonLXDxHekY-1"
          },
          {
            title: "Landing page (Websites Vs Landing Pages)",
            objective: [
              "Design and optimize landing pages to improve user engagement and conversion rates on guvi.in.",
              "Explore differences between websites and landing pages, focusing on best practices for designing effective landing page experiences on guvi.in."
            ],
            outcome: [
              "Designed and optimized landing pages for guvi.in, incorporating persuasive messaging and clear calls-to-action to improve user engagement and conversion rates.",
              "Identified key differences between websites and landing pages, implementing best practices to create effective landing page experiences that drive user action on guvi.in."
            ],
            difficulties: [
              "Balancing visual appeal with conversion-focused design elements on landing pages to maximize user engagement and drive desired actions on guvi.in.",
              "Optimizing landing page performance and conversion rates through A/B testing and iterative design improvements on guvi.in."
            ],
            link: "https://www.figma.com/file/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?type=design&node-id=129%3A8517&mode=design&t=M1PJKbonLXDxHekY-1"
          },
          {
            title: "Analysis of competitor landing page for guvi.in",
            objective: [
              "Conduct a comparative analysis of competitor landing pages to identify strengths, weaknesses, and opportunities for improvement for guvi.in.",
              "Gather insights and inspiration from competitor landing pages to inform the redesign and optimization of guvi.in's own landing pages."
            ],
            outcome: [
              "Identified key design elements and strategies used by competitors to drive user engagement and conversion on their landing pages, providing valuable insights for guvi.in's own landing page optimization efforts.",
              "Incorporated competitive analysis findings into the redesign and optimization of guvi.in's landing pages, resulting in improved user engagement and conversion rates."
            ],
            difficulties: [
              "Ensuring objectivity and impartiality in the analysis of competitor landing pages while identifying actionable insights and opportunities for guvi.in's own landing page optimization efforts.",
              "Balancing inspiration from competitor landing pages with the need to maintain guvi.in's brand identity and user experience standards."
            ],
            link: "https://www.figma.com/file/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?type=design&node-id=149%3A6797&mode=design&t=M1PJKbonLXDxHekY-1"
          },
          {
            title: "UX Writing",
            objective: [
              "Develop clear and concise UX copy to enhance user understanding and guide interactions within the product on guvi.in.",
              "Explore best practices for UX writing, focusing on effective communication and user engagement on guvi.in."
            ],
            outcome: [
              "Developed clear and concise UX copy for guvi.in, improving user comprehension and guiding interactions within the product.",
              "Implemented best practices for UX writing, including using user-friendly language, clear instructions, and persuasive messaging to enhance user engagement on guvi.in."
            ],
            difficulties: [
              "Balancing brevity with clarity in UX copywriting to convey essential information and guide user interactions effectively on guvi.in.",
              "Adapting UX writing to different user personas and contexts while maintaining consistency and coherence across guvi.in's products and services."
            ],
            link: "https://www.figma.com/file/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?type=design&node-id=154%3A42392&mode=design&t=M1PJKbonLXDxHekY-1"
          },
          {
            title: "HackerKid Pricing Page redesign",
            objective: [
              "Contribute to the redesign of the pricing page for HackerKid, focusing on clarity, transparency, and persuasive messaging.",
              "Optimize the pricing page to improve user comprehension and drive conversions for HackerKid on guvi.in."
            ],
            outcome: [
              "Collaborated on the redesign of HackerKid's pricing page, incorporating clear pricing tiers, transparent billing information, and persuasive messaging to improve user comprehension and drive conversions.",
              "Implemented A/B testing and iterative design improvements to optimize the pricing page for HackerKid, resulting in improved user engagement and conversion rates on guvi.in."
            ],
            difficulties: [
              "Balancing transparency and persuasive messaging in the pricing page redesign to provide users with clear information while encouraging them to take action on guvi.in.",
              "Addressing technical constraints and compatibility issues when implementing design changes and optimizations on guvi.in."
            ],
            link: "https://www.figma.com/file/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?type=design&node-id=168%3A13577&mode=design&t=M1PJKbonLXDxHekY-1"
          },
          {
            title: "Ai in UI/UX",
            objective: [
              "Explore the integration of artificial intelligence (AI) technologies in UI/UX design to enhance user experiences and streamline workflows on guvi.in.",
              "Investigate AI-driven design tools and techniques for optimizing UI/UX processes and outcomes on guvi.in."
            ],
            outcome: [
              "Researched and experimented with AI-driven design tools and techniques to streamline UI/UX processes and enhance user experiences on guvi.in.",
              "Implemented AI-powered features and functionalities in UI/UX design workflows, such as automated prototyping, personalized content recommendations, and predictive user analytics on guvi.in."
            ],
            difficulties: [
              "Understanding the capabilities and limitations of AI technologies in the context of UI/UX design and finding suitable applications for guvi.in's specific needs.",
              "Adapting existing UI/UX workflows and processes to incorporate AI-driven tools and techniques, including addressing technical challenges and ensuring compatibility with existing systems on guvi.in."
            ],
            link: "https://www.figma.com/file/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?type=design&node-id=187%3A3407&mode=design&t=M1PJKbonLXDxHekY-1"
          },
          {
            title: "Ui UX in SAAS",
            objective: [
              "Explore the unique challenges and opportunities in designing for Software as a Service (SaaS) products, focusing on usability, scalability, and user retention on guvi.in.",
              "Investigate best practices and design strategies for creating intuitive and user-friendly SaaS interfaces that cater to guvi.in's target audience."
            ],
            outcome: [
              "Gained insights into the key considerations and design principles specific to SaaS products, including scalability, flexibility, and user onboarding experiences on guvi.in.",
              "Developed user-centric design solutions and interfaces tailored to guvi.in's SaaS offerings, emphasizing ease of use, efficient workflows, and seamless integration with other systems."
            ],
            difficulties: [
              "Understanding the complexities of SaaS product architectures and workflows, including managing multiple user roles, permissions, and data interactions on guvi.in.",
              "Balancing the need for customization and flexibility in SaaS interfaces with the importance of maintaining consistency, coherence, and ease of use for guvi.in's users."
            ],
            link: "https://www.figma.com/file/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?type=design&node-id=197%3A90&mode=design&t=M1PJKbonLXDxHekY-1"
          },
          {
            title: "Guvi Landing page redesign for guvi.in",
            objective: [
              "Lead the redesign of the Guvi landing page to modernize the interface, improve navigation, and enhance brand perception on guvi.in.",
              "Optimize the landing page layout, content, and visuals to increase user engagement, retention, and conversion rates for guvi.in."
            ],
            outcome: [
              "Designed a visually appealing and user-friendly landing page for Guvi, incorporating modern design trends, intuitive navigation elements, and compelling calls-to-action on guvi.in.",
              "Implemented A/B testing and iterative design improvements to optimize the landing page for conversion, resulting in increased user engagement and conversion rates on guvi.in."
            ],
            difficulties: [
              "Balancing the need for a visually appealing design with the importance of clear messaging and intuitive navigation to effectively communicate Guvi's value proposition and offerings on guvi.in.",
              "Addressing technical constraints and compatibility issues when implementing design changes and optimizations across different devices and platforms for guvi.in."
            ],
            link: "https://www.figma.com/file/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?type=design&node-id=244%3A8492&mode=design&t=M1PJKbonLXDxHekY-1"
          },
          {
            title: "Guvi Courses Page redesign for guvi.in",
            objective: [
              "Redesign the Guvi courses page to improve content organization, visual hierarchy, and user engagement for guvi.in.",
              "Enhance the user experience on the courses page by optimizing navigation, search functionality, and access to course information on guvi.in."
            ],
            outcome: [
              "Redesigned the Guvi courses page with a focus on improving content discoverability, visual appeal, and user engagement through intuitive navigation and clear information architecture on guvi.in.",
              "Implemented interactive elements, such as filters, sorting options, and personalized recommendations, to enhance the user experience and encourage exploration of Guvi's course offerings on guvi.in."
            ],
            difficulties: [
              "Balancing the presentation of a large volume of course content with the need for clear and concise information presentation to facilitate user decision-making and exploration on guvi.in.",
              "Optimizing the courses page for performance and accessibility across different devices and screen sizes while maintaining a consistent and cohesive user experience for guvi.in."
            ],
            link: "https://www.figma.com/file/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?type=design&node-id=244%3A8492&mode=design&t=M1PJKbonLXDxHekY-1"
          },
          {
            title: "Course Landing page redesign for guvi.in",
            objective: [
              "Design customized landing pages for individual Guvi courses to provide targeted information and encourage conversions for guvi.in.",
              "Optimize the layout, content, and visuals of course landing pages to effectively communicate course benefits, features, and enrollment options on guvi.in."
            ],
            outcome: [
              "Created visually compelling and informative landing pages for Guvi courses, highlighting key course features, benefits, and testimonials to drive user interest and enrollment on guvi.in.",
              "Implemented A/B testing and performance tracking to evaluate the effectiveness of course landing pages and make data-driven design decisions to improve conversion rates on guvi.in."
            ],
            difficulties: [
              "Ensuring consistency with Guvi's brand identity and design guidelines while tailoring landing page designs to the unique characteristics and target audiences of individual courses on guvi.in.",
              "Addressing technical constraints and compatibility issues when implementing design changes and optimizations on course landing pages across different devices and platforms for guvi.in."
            ],
            link: "https://www.figma.com/file/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?type=design&node-id=332%3A3442&mode=design&t=M1PJKbonLXDxHekY-1"
          },
          {
            title: "User journey mapping for guvi.in",
            objective: [
              "Map out user journeys across guvi.in's products and services to identify key touchpoints, pain points, and opportunities for improvement.",
              "Visualize and analyze user journeys to gain insights into user behavior, preferences, and needs, informing design decisions and product enhancements on guvi.in."
            ],
            outcome: [
              "Developed comprehensive user journey maps for guvi.in, highlighting critical user interactions, pain points, and opportunities for optimization across various touchpoints and channels.",
              "Utilized user journey maps to identify friction points, optimize user flows, and prioritize design improvements to enhance the overall user experience and drive user satisfaction on guvi.in."
            ],
            difficulties: [
              "Gathering and synthesizing data from multiple sources, including user feedback, analytics, and usability testing, to accurately map out user journeys and identify pain points for guvi.in.",
              "Balancing the need for detail and granularity in user journey mapping with the need for simplicity and clarity to effectively communicate insights and recommendations to stakeholders on guvi.in."
            ],
            link: "https://www.figma.com/file/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?type=design&node-id=403%3A27615&mode=design&t=M1PJKbonLXDxHekY-1"
          },
          {
            title: "Guvi for Corporates redesign",
            objective: [
              "Collaborate on the redesign of Guvi's corporate offerings, focusing on tailored solutions for enterprise clients and seamless integration with existing workflows.",
              "Optimize Guvi's corporate platform for usability, scalability, and customization to meet the unique needs and requirements of corporate clients."
            ],
            outcome: [
              "Redesigned Guvi's corporate platform with a focus on enhancing usability, scalability, and customization to better serve the needs of enterprise clients and facilitate seamless integration with corporate workflows.",
              "Implemented custom features and functionalities, such as single sign-on (SSO), role-based access control (RBAC), and integration with corporate learning management systems (LMS), to provide a tailored and integrated solution for corporate clients."
            ],
            difficulties: [
              "Understanding the diverse needs and requirements of corporate clients, including compliance, security, and scalability considerations, when redesigning Guvi's corporate platform on guvi.in.",
              "Ensuring seamless integration and interoperability with existing corporate systems, such as HRIS, CRM, and LMS platforms, while maintaining a consistent and cohesive user experience for corporate users on guvi.in."
            ],
            link: "https://www.figma.com/file/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?type=design&node-id=408%3A3&mode=design&t=M1PJKbonLXDxHekY-1"
          }
      ];
      
  
    return (
        <>
        <NavBar/>
<section className="bg-white  mt-20 pl-20 pr-20 pb-20" >
    
    <div className="container mx-auto p-4">
            <div className="flex items-center justify-center mb-4">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLWXHj5gqLqQflNa09M95WUhG7YDkJ8xjFGoO7llOfkQ&s" alt="Guvi Logo" className="rounded-full" />
            </div>
            <div className="text-center mb-4">
                <h1 className="text-2xl font-bold">Guvi Internship Report March 2024</h1>
                <p className="text-lg">Internship Period: March - April 2024</p>
                <p className="text-lg">Position: Design Intern</p>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow-md">
                <h2 className="text-lg font-bold mb-2">Overview</h2>
                <p>
                    During the internship period at GUVI, I was engaged in various aspects of design aimed at enhancing user experience and product usability. This report provides an overview of the tasks undertaken, skills developed, and contributions made during the internship.
                </p>
            </div>
        </div>
  <div className="container px-6 py-8 mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3">
    {reportData.map((report, index) => (
      <div key={index} className="bg-white rounded-lg shadow-md transition duration-500 ease-in-out transform hover:scale-105 ">
        <div className="px-6 py-4" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <h2 className="text-xl font-semibold text-gray-800  mb-2">{report.title}</h2>
          <div className="mb-4">
            <h3 className="text-gray-700 font-semibold">Objective:</h3>
            <ul className="list-disc list-inside text-gray-600 ">
              {report.objective.map((point, idx) => (
                <li key={idx} className="ml-4">{point}</li>
              ))}
            </ul>
          </div>
          <div className="mb-4">
            <h3 className="text-gray-700  font-semibold">Outcome:</h3>
            <ul className="list-disc list-inside text-gray-600 ">
              {report.outcome.map((point, idx) => (
                <li key={idx} className="ml-4">{point}</li>
              ))}
            </ul>
          </div>
          <div className="mb-4">
            <h3 className="text-gray-700  font-semibold">Difficulties:</h3>
            <ul className="list-disc list-inside text-gray-600 ">
              {report.difficulties.map((point, idx) => (
                <li key={idx} className="ml-4">{point}</li>
              ))}
            </ul>
          </div>
          <div style={{ marginTop: 'auto' }}>
    <a href={report.link} className="block w-full py-2 px-4 bg-gray-800 hover:bg-gray-600 text-white font-semibold rounded-lg shadow-md  transition duration-300 text-center">View Full Report</a>
  </div>
        </div>
      </div>
    ))}
  </div>
</section>
<Footer/>
</>

    );
  };
  
  export default GuviInternReport;
