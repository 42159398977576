import React, { useState, useEffect } from "react";
import logo from "../assets/logo.png";
import { Link } from 'react-router-dom';

const NavBar = () => {

  return (
<nav
  className="bg-white w-full border-b fixed top-0 z-50 pt-2 pb-2"
>
  <div className="flex items-center justify-between px-4 max-w-screen-xl mx-auto md:px-8">
  <Link to="/">
  <img src={logo} width={120} height={50} alt="Float UI logo" />
</Link>
<Link to="/#contact">
  <button className="py-3 px-4 text-white bg-gray-800 hover:bg-gray-600 rounded-md shadow">
    Get Connect
  </button>
</Link>
  </div>
</nav>
  );
};

export default NavBar;
