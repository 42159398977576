import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';

export default () => {

    const educations = [
        {
            icon: <FontAwesomeIcon icon={faGraduationCap} className="w-6 h-6" />,
            institution: "SRI ESHWAR COLLEGE OF ENGINEERING",
            degree: "Bachelors in Electronics and Communication Engineering",
            date: "2021-2025",
            additionalInfo: "CGPA: 7.82"
        },
        {
            icon: <FontAwesomeIcon icon={faGraduationCap} className="w-6 h-6" />,
            institution: "L.K. Higher Secondary School",
            degree: "Standard - 10th (SSLC)",
            date: "2019-2021",
            additionalInfo: "Percentage: 87%"
        },
        {
            icon: <FontAwesomeIcon icon={faGraduationCap} className="w-6 h-6" />,
            institution: "L.K. Higher Secondary School",
            degree: "Standard - 12th (HSC)",
            date: "2019-2021",
            additionalInfo: "Percentage: 82%"
        }
    ];

    return (
        <section id="education" className="relative py-28 ">
            <div className="relative z-10 max-w-screen-xl mx-auto px-4 text-300 justify-between gap-24 lg:flex md:px-8">
                <div className="max-w-xl">
                    <h3 className=" text-3xl font-semibold sm:text-4xl text-black">
                        Education
                    </h3>
                </div>
                <div className="mt-12 lg:mt-0">
                    <ul className="grid gap-8 sm:grid-cols-2">
                        {educations.map((education, idx) => (
                            <li key={idx} className="flex gap-x-4">
                                <div className="flex-none w-12 h-12 bg-customYellow text-white rounded-lg flex items-center justify-center">
                                    {education.icon}
                                </div>
                                <div>
                                    <h4 className="text-lg text-100 font-semibold text-black">
                                        {education.institution}
                                    </h4>
                                    <p className="mt-3 text-gray-800">
                                        {education.date} | {education.additionalInfo}
                                    </p>
                                    <p className="mt-3 text-300 text-gray-800">
                                        {education.degree}
                                    </p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="absolute inset-0 max-w-md mx-auto h-72 blur-[118px]" style={{ background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)" }}></div>
        </section>
    )
}
